<template>
	<div class="supplyChain">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 55%;align-items: initial;flex-direction: column;" class="flex">
						<span
							style="font-size: 0.36rem; color: #FFFFFF;font-family: Source Han Sans CN;">力瀚S2B2C供应链系统</span>
						<span
							style="font-size: 0.17rem;color: #fff;font-family: Source Han Sans CN;margin-top: 0.30rem;">满足批发+供货+销售于一体的电商平台</span>
						<div class="sqsy" @click="applyFromshow = true">申请试用</div>
					</div>
				</div>
				<div class="banner-body-img flex">

				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/supplyChainbanner.png">
		</div>

		<div class="introduce">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">力瀚S2B2C供应链系统介绍</span><br />
					<span class="worry-tit-b">力瀚运用成熟的技术，通过供应链平台（S），提供海量货源给到渠道商 /
						采购商（B），结合互联网技术，赋能B端完成对C端消费者的服务，为商家打造一个成熟的S2B2C供应链平台。</span>
				</div>
			</div>

			<div class="introduce-box">
				<img style="width: 100%;" src="../assets/img/supplyChain-introduce.png">
			</div>
		</div>

		<div class="group">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">适用群体</span><br />
				</div>
			</div>

			<div class="group-box flex">
				<div class="group-box-cell flex">
					<img style="width: 0.92rem;margin-bottom: 0.10rem;" src="../assets/img/supplyChain-group1.png">
					<div class="group-box-cell-tit">上下游供应链打通</div>
					<div class="group-box-cell-lab">
						力瀚提供线上平台的同时，线下帮助企业打通上下游的供应链。帮助小b向上游供应商集成采购，获得的服务。
					</div>
				</div>
				<div class="group-box-cell flex">
					<img style="width: 0.92rem;margin-bottom: 0.10rem;" src="../assets/img/supplyChain-group2.png">
					<div class="group-box-cell-tit">电商新零售平台</div>
					<div class="group-box-cell-lab">把小b作为中间商，一端连接工厂，一端供给消费者，打造类似云集的新零售模式。
					</div>
				</div>
				<div class="group-box-cell flex">
					<img style="width: 0.92rem;margin-bottom: 0.10rem;" src="../assets/img/supplyChain-group3.png">
					<div class="group-box-cell-tit">垂直行业赋能</div>
					<div class="group-box-cell-lab">
						赋能传统产业链网络化和智能化，提供标准化产品和服务，为商家提供决策支持，为传统行业实现数据赋能。
					</div>
				</div>
				<div class="group-box-cell flex">
					<img style="width: 0.92rem;margin-bottom: 0.10rem;" src="../assets/img/supplyChain-group4.png">
					<div class="group-box-cell-tit">零售渠道商业务转型 </div>
					<div class="group-box-cell-lab">
						整合优质供应商，与渠道商形成业务生
						态，让渠道商从客户经营中获得收益。
						平台为渠道商业务转型创造更多商机
					</div>
				</div>
			</div>

			<div class="But" style="margin-top: 0.60rem;" @click="applyFromshow = true">获取我的行业方案</div>
		</div>

		<div class="link">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t" style="color: #fff;">力瀚S2B2C全模式生态链路</span><br />
				</div>
			</div>

			<div class="link-box">
				<img style="width: 100%;" src="../assets/img/supplyChain-link.png">
			</div>
		</div>

		<div class="Value">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">力瀚S2B2C模式核心应用价值</span><br />
					<span class="worry-tit-b">s2b2c模式的核心在于对新零售、新商业未来的思考，降低成本，提升整体效率</span>
				</div>
			</div>

			<div class="Value-box">
				<div class="Value-box-cent flex">
					<img class="Value-box-cent-img" src="../assets/img/supplyChain-Value.png">
					<div class="Value-box-cent-cell flex Value-box-cent-cell5">
						<img style="width: 0.44rem;height: 0.44rem;" src="../assets/img/supplyChain-Value-icon1.png">
						<div style="flex: 1;text-align: left;margin-left: 0.10rem;">
							<div style="color: #333333;font-weight: 400;font-size: 0.20rem;margin-bottom: 0.10rem;">
								集成采购降低成本
							</div>
							<div style="color: #666666;font-weight: 400;font-size: 0.14rem;">拥有上游供应链供货议价能力，统一大批
								量采购降低商品采购成本
							</div>
						</div>
					</div>

					<div class="Value-box-cent-cell flex Value-box-cent-cell1">
						<img style="width: 0.44rem;height: 0.44rem;" src="../assets/img/supplyChain-Value-icon5.png">
						<div style="flex: 1;text-align: left;margin-left: 0.10rem;">
							<div style="color: #333333;font-weight: 400;font-size: 0.20rem;margin-bottom: 0.10rem;">
								供货商自主推广
							</div>
							<div style="color: #666666;font-weight: 400;font-size: 0.14rem;">供货商在平台自主开店，自然需要推广，
								同时为平台带来更多光
							</div>
						</div>
					</div>

					<div class="Value-box-cent-cell flex Value-box-cent-cell2">
						<img style="width: 0.44rem;height: 0.44rem;" src="../assets/img/supplyChain-Value-icon2.png">
						<div style="flex: 1;text-align: left;margin-left: 0.10rem;">
							<div style="color: #333333;font-weight: 400;font-size: 0.20rem;margin-bottom: 0.10rem;">
								降低获客成本
							</div>
							<div style="color: #666666;font-weight: 400;font-size: 0.14rem;">
								平台大批量发展批发商，1对1卖货拿提成,让其身边的消费者大量汇聚</div>
						</div>
					</div>

					<div class="Value-box-cent-cell flex Value-box-cent-cell3">
						<img style="width: 0.44rem;height: 0.44rem;" src="../assets/img/supplyChain-Value-icon3.png">
						<div style="flex: 1;text-align: left;margin-left: 0.10rem;">
							<div style="color: #333333;font-weight: 400;font-size: 0.20rem;margin-bottom: 0.10rem;">
								智能化大数据
							</div>
							<div style="color: #666666;font-weight: 400;font-size: 0.14rem;">
								消费者、供货商、采购商的交易数据均可留存平台，分析挖掘更多价值
							</div>
						</div>
					</div>

					<div class="Value-box-cent-cell flex Value-box-cent-cell4">
						<img style="width: 0.44rem;height: 0.44rem;" src="../assets/img/supplyChain-Value-icon4.png">
						<div style="flex: 1;text-align: left;margin-left: 0.10rem;">
							<div style="color: #333333;font-weight: 400;font-size: 0.20rem;margin-bottom: 0.10rem;">
								购物信任度高
							</div>
							<div style="color: #666666;font-weight: 400;font-size: 0.14rem;">对于消费者朋友及家人而言，身边推荐的
								商品保真又使宜，信任度更高</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="system">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">系统功能</span><br />
				</div>
			</div>

			<div class="system-box flex">
				<div class="system-box-cell">
					<img style="width: 0.40rem;margin-top: 0.5rem;" src="../assets/img/supplyChain-system1.png">
					<div class="system-box-cell-tit">供货商自主推广</div>
					<div class="system-box-cell-lab">平台可以为供货商提供专业的入驻流程，审核完成后即可成为供货商</div>
				</div>
				<div class="system-box-cell">
					<img style="width: 0.40rem;margin-top: 0.5rem;" src="../assets/img/supplyChain-system2.png">
					<div class="system-box-cell-tit">批发商品发布</div>
					<div class="system-box-cell-lab">供货商可以发布批发商品，针对商品的不同购买数量设置批发价</div>
				</div>
				<div class="system-box-cell">
					<img style="width: 0.40rem;margin-top: 0.5rem;" src="../assets/img/supplyChain-system3.png">
					<div class="system-box-cell-tit">批发商品分享</div>
					<div class="system-box-cell-lab">供货商可以将批发商品分享给平台的采购商，让采购商可以直接销售</div>
				</div>
				<div class="system-box-cell">
					<img style="width: 0.40rem;margin-top: 0.5rem;" src="../assets/img/supplyChain-system4.png">
					<div class="system-box-cell-tit">供货商智能结算</div>
					<div class="system-box-cell-lab">按照周期可以申请佣金结算，平台审核完成后将佣金转给供货商</div>
				</div>
				<div class="system-box-cell">
					<img style="width: 0.40rem;margin-top: 0.5rem;" src="../assets/img/supplyChain-system5.png">
					<div class="system-box-cell-tit">多类型客户管理</div>
					<div class="system-box-cell-lab">消费者、采购商、批发商均可通过后台组织统一管理运营</div>
				</div>
				<!-- <div class="system-box-cell">
					<img style="width: 0.40rem;" src="../assets/img/supplyChain-system6.png">
					<div class="system-box-cell-tit">满足大额支付结算</div>
					<div class="system-box-cell-lab">根据需求可开发对接大额支付方式，满足商家的大批量采购付款</div>
				</div> -->
			</div>
		</div>

		<div class="demonstration">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">合作特渠展示</span><br />
				</div>
			</div>
			<div class="demonstration-enterprisebox" style="width: 14.36rem; margin: 0 auto;margin-top: 0.60rem;">
				<img style="width: 100%;" src="../assets/img/enterprise-tq.png">
			</div>
		</div>

		<div class="demonstration">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">演示地址</span><br />
				</div>
			</div>

			<div class="demonstration-box flex">
				<div style="width: 9.8rem; position: relative;height: 100%;">
					<img style="width: 100%;height: 100%;" src="../assets/img/supplyChain-demonstration-L.png">
					<div style="position: absolute;z-index: 11;width: 100%;height: 100%;top: 0;">
						<div class="demonstration-box-imgT flex"
							style="border-bottom: 1px solid #f7f7f7;padding-bottom: 0.20rem;">
							<div style="width: 40%;color: #333333;font-weight: 400;font-size: 0.17rem;">演示地址</div>
							<div style="width: 60%;color: #333333;font-weight: 400;font-size: 0.17rem;">演示地址</div>
						</div>
						<div class="demonstration-box-imgT flex" style="margin-top: 0.30rem;">
							<div style="width: 40%;color: #999999;font-weight: 400;font-size: 0.14rem;">PC端</div>
							<div style="flex: 1; color: #999999;font-weight: 400;font-size: 0.14rem;">PC端</div>
							<div style="flex: 1; color: #2C8DF8;font-weight: 400;font-size: 0.14rem;"
								@click="customerService = true">联系客服获取账号</div>
						</div>
						<div class="demonstration-box-imgT flex" style="margin-top: 0.30rem;">
							<div style="width: 40%; color: #999999;font-weight: 400;font-size: 0.14rem;">商家H5端</div>
							<div style="flex: 1; color: #999999;font-weight: 400;font-size: 0.14rem;">商家H5端</div>
							<div style="flex: 1; color: #2C8DF8;font-weight: 400;font-size: 0.14rem;"
								@click="customerService = true">联系客服获取账号</div>
						</div>
					</div>
				</div>
				<div style="flex: 1;">
					<img style="width: 2.04rem;" src="../assets/img/customerService-code.png">
					<div style="color: #333333;font-size: 0.17rem;margin-top: 0.20rem;">供应链H5端</div>
				</div>
			</div>

			<div class="But" style="margin-top: 0.60rem;" @click="customerService = true">获取后台地址</div>
		</div>

		<!-- <div class="case">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">案例介绍</span><br />
				</div>
			</div>

			<div class="case-box flex">
				<div class="case-box-cell">
					<img src="../assets/img/supplyChain-case.png">
					<div class="case-box-cell-B">
						<div style="color: #333333;font-size: 13px;margin-bottom: 10px;">干匠一品</div>
						<div class="case-box-cell-B-lab">
							完善品牌线上家居销售渠道，线下赋能，
							降低渠道成本，缩短获客、交易、服务...
						</div>
						<div style="color: #999999;font-size: 9px;padding-bottom: 10px;border-bottom: 1px solid #f7f7f7;text-align: left;">
							<span>广州市</span>
							<span style="margin-left: 20px;">其他行业，S2B2C</span>
						</div>
						
						<div style="color: #999999;font-size: 9px;margin-top: 10px;text-align: left;">
							<span>使用终端</span>
							<span style="margin-left: 20px;color: #666;">PC 微商城 小程序</span>
						</div>
					</div>
				</div>
				<div class="case-box-cell">
					<img src="../assets/img/supplyChain-case.png">
					<div class="case-box-cell-B">
						<div style="color: #333333;font-size: 13px;margin-bottom: 10px;">干匠一品</div>
						<div class="case-box-cell-B-lab">
							完善品牌线上家居销售渠道，线下赋能，
							降低渠道成本，缩短获客、交易、服务...
						</div>
						<div style="color: #999999;font-size: 9px;padding-bottom: 10px;border-bottom: 1px solid #f7f7f7;text-align: left;">
							<span>广州市</span>
							<span style="margin-left: 20px;">其他行业，S2B2C</span>
						</div>
						
						<div style="color: #999999;font-size: 9px;margin-top: 10px;text-align: left;">
							<span>使用终端</span>
							<span style="margin-left: 20px;color: #666;">PC 微商城 小程序</span>
						</div>
					</div>
				</div>
				<div class="case-box-cell">
					<img src="../assets/img/supplyChain-case.png">
					<div class="case-box-cell-B">
						<div style="color: #333333;font-size: 13px;margin-bottom: 10px;">干匠一品</div>
						<div class="case-box-cell-B-lab">
							完善品牌线上家居销售渠道，线下赋能，
							降低渠道成本，缩短获客、交易、服务...
						</div>
						<div style="color: #999999;font-size: 9px;padding-bottom: 10px;border-bottom: 1px solid #f7f7f7;text-align: left;">
							<span>广州市</span>
							<span style="margin-left: 20px;">其他行业，S2B2C</span>
						</div>
						
						<div style="color: #999999;font-size: 9px;margin-top: 10px;text-align: left;">
							<span>使用终端</span>
							<span style="margin-left: 20px;color: #666;">PC 微商城 小程序</span>
						</div>
					</div>
				</div>
				<div class="case-box-cell">
					<img src="../assets/img/supplyChain-case.png">
					<div class="case-box-cell-B">
						<div style="color: #333333;font-size: 13px;margin-bottom: 10px;">干匠一品</div>
						<div class="case-box-cell-B-lab">
							完善品牌线上家居销售渠道，线下赋能，
							降低渠道成本，缩短获客、交易、服务...
						</div>
						<div style="color: #999999;font-size: 9px;padding-bottom: 10px;border-bottom: 1px solid #f7f7f7;text-align: left;">
							<span>广州市</span>
							<span style="margin-left: 20px;">其他行业，S2B2C</span>
						</div>
						
						<div style="color: #999999;font-size: 9px;margin-top: 10px;text-align: left;">
							<span>使用终端</span>
							<span style="margin-left: 20px;color: #666;">PC 微商城 小程序</span>
						</div>
					</div>
				</div>
			</div>
			
			<div class="But" style="margin-top: 60px;">更多案例</div>
		</div> -->

		<div class="file">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">相关文档</span><br />
				</div>
			</div>
			<div class="file-box flex">
				<div class="file-box-cell">
					<img style="width: 100%;" src="../assets/img/supplyChain-file-L.png">
					<div class="file-box-cell-box">
						<div class="file-box-cell-box-span flex">
							<div style="flex: 1;">力瀚的s2b2c系统和b2b2c系统有什么区别？</div>
						</div>
						<div class="file-box-cell-box-span flex">
							<div style="flex: 1;">力瀚系统的零售商和采购商的区别是什么？</div>
						</div>
						<div class="file-box-cell-box-span flex">
							<div style="flex: 1;">力瀚系统的供货商是如何入驻的？</div>
						</div>
					</div>
				</div>
				<div class="file-box-cell">
					<img style="width: 100%;" src="../assets/img/supplyChain-file-R.png">
					<div class="file-box-cell-box">
						<div class="file-box-cell-box-span flex">
							<div style="flex: 1;">供应链S2B2C商城系统如何选型</div>
							<div style="width: 30%;">2021-08-10</div>
						</div>
						<div class="file-box-cell-box-span flex">
							<div style="flex: 1;">供应链S2B2C商城系统如何选型</div>
							<div style="width: 30%;">2021-08-10</div>
						</div>
						<div class="file-box-cell-box-span flex">
							<div style="flex: 1;">供应链S2B2C商城系统如何选型</div>
							<div style="width: 30%;">2021-08-10</div>
						</div>
					</div>
				</div>
				<!-- <img style="width: 335px;" src="../assets/img/supplyChain-file-L.png" > -->
				<!-- <img style="width: 335px;" src="../assets/img/supplyChain-file-R.png" > -->
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				customerService: false
			}

		},
		components: {
			// Canvas，
			applyFrom,
			customerService
		},
		methods: {
			close() {
				this.applyFromshow = false;
			},
			closePopup() {
				this.customerService = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		width: 100%;
		height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
				position: relative;
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;

				// align-items: initial;
				.sqsy {
					width: 1.80rem;
					height: 0.50rem;
					border-radius: 6px;
					// margin: 0 auto;
					margin-top: 0.20rem;
					background-image: url(../assets/img/sqsy.png);
					font-size: 0.25rem;
					text-align: center;
					line-height: 0.50rem;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.characteristic-tit {
		// width: 40%;
		margin: 0 auto;
		text-align: center;
		line-height: 0.30rem;

		.worry-tit-t {
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333;
		}

		.worry-tit-b {
			font-size: 0.12rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #666;
		}
	}

	.But {
		width: 1.88rem;
		height: 0.50rem;
		background: linear-gradient(90deg, #2E89F9, #06D8EE);
		border-radius: 1px;
		margin: 0 auto;
		font-size: 0.18rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 0.50rem;
	}

	.introduce {
		width: 100%;
		padding: 0.60rem 0;

		.introduce-box {
			width: 13.86rem;
			margin: 0 auto;
			margin-top: 0.60rem;
		}
	}

	@media screen and (max-width: 750px) {
		.group-box {
			width: 90% !important;
		}

		.banner-body-title {
			width: 100% !important;
		}

		.introduce-box {
			width: 90% !important;
		}

		.link-box {
			width: 90% !important;
		}

		.demonstration-enterprisebox {
			width: 90% !important;
		}

		.demonstration-box {
			width: 90% !important;


			// .demonstration-box-imgT {
			// 	margin-top: 0.2rem !important;
			// }
		}

		.file-box {
			width: 90% !important;
		}

		.file-box-cell-box {
			padding: 0 !important;
		}

		.Value-box-cent {
			width: 5rem !important;
			height: 5rem !important;

			.Value-box-cent-img {
				width: 3.94rem !important;
			}

			.Value-box-cent-cell {
				width: 2.4rem !important;
			}
		}

		.system-box {
			width: 90% !important;
		}
	}

	.group {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.group-box {
			// width: 750px;
			width: 14.00rem;
			margin: 0 auto;
			margin-top: 0.60rem;
			background-color: #fff;

			.group-box-cell {
				flex: 1;
				// height: 220px;
				padding: 0.20rem 0.10rem;
				flex-direction: column;

				.group-box-cell-tit {
					font-size: 0.14rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					margin-bottom: 0.10rem;
				}

				.group-box-cell-lab {
					font-size: 0.09rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #999999;
				}
			}
		}
	}

	.link {
		width: 100%;
		padding: 0.60rem 0;
		background: #15151C;

		.link-box {
			width: 11.82rem;
			margin: 0 auto;
			margin-top: 0.60rem;
		}
	}

	.Value {
		width: 100%;
		padding: 0.60rem 0;

		.Value-box {
			// width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;

			.Value-box-cent {
				width: 6rem;
				height: 6rem;
				border-radius: 100%;
				border: 1px dashed #188CF3;
				margin: 0 auto;
				position: relative;

				.Value-box-cent-img {
					width: 4.94rem;
				}

				.Value-box-cent-cell4 {
					right: -1rem;
					bottom: 0.60rem;
				}

				.Value-box-cent-cell3 {
					right: -1rem;
					top: 1.20rem;
				}

				.Value-box-cent-cell5 {
					left: -1rem;
					top: 1.2rem;
				}

				.Value-box-cent-cell2 {
					left: 0;
					right: 0;
					margin: 0 auto;
					top: -0.40rem;
				}

				.Value-box-cent-cell1 {
					left: -1.0rem;
					bottom: 0.60rem;
				}

				.Value-box-cent-cell {
					width: 3.10rem;
					// height: 60px;
					position: absolute;
					box-shadow: 0px 1px 12px 0px rgba(174, 174, 174, 0.2);
					border-radius: 2px;
					z-index: 11;
					background-color: #fff;
					justify-content: initial;
					align-items: initial;
					padding: 0.20rem 0;
					padding-left: 0.10rem;
					padding-right: 0.20rem;
				}
			}
		}
	}

	.system {
		width: 100%;
		padding: 0.60rem 0;

		.system-box {
			width: 70%;
			margin: 0 auto;
			margin-top: 0.60rem;
			flex-wrap: wrap;
			// justify-content: space-between;
			justify-content: space-around;
			.system-box-Main {
				margin-left: 9.5%;
				margin-right: 9.5%;
			}

			.system-box-cell {
				width: 27%;
				margin-bottom: 0.60rem;

				.system-box-cell-tit {
					font-size: 0.13rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					margin-top: 0.20rem;
				}

				.system-box-cell-lab {
					font-size: 0.09rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;
					margin-top: 0.10rem;
					margin-bottom: 0.2rem;
				}
			}
		}
	}

	.demonstration {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.demonstration-box {
			// width: 90%;
			width: 12.00rem;
			height: 2.5rem;
			margin: 0 auto;
			margin-top: 0.60rem;

			.demonstration-box-imgT {
				width: 90%;
				margin: 0 auto;
				margin-top: 0.50rem;
				text-align: left;


			}
		}
	}

	.case {
		width: 100%;
		padding: 0.60rem 0;

		.case-box {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;
			justify-content: space-between;

			.case-box-cell {
				width: 24%;
				box-shadow: 0px 1px 12px 0px rgba(174, 174, 174, 0.2);

				.case-box-cell-B {
					padding: 0.10rem;

					.case-box-cell-B-lab {
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999999;
						font-size: 0.12rem;
						margin-bottom: 0.10rem;
						text-align: left;
					}
				}
			}
		}
	}

	.file {
		width: 100%;
		padding: 0.60rem 0;

		// background-color: #f7f7f7;
		.file-box {
			width: 68%;
			justify-content: space-between;
			margin: 0 auto;
			margin-top: 0.60rem;

			.file-box-cell {
				// margin-right: 0.30rem;
				background: #FFFFFF;
				box-shadow: 0px 1px 12px 0px rgba(174, 174, 174, 0.2);

				.file-box-cell-box {
					width: 100%;
					padding: 0.10rem 0.20rem;
					text-align: left;

					.file-box-cell-box-span {
						padding: 0.10rem 0;
						font-size: 0.14rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
					}
				}
			}
		}
	}
</style>
